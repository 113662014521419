import Sketch from "./fake3d/src";
const gl = document.getElementById("gl");
const original = document.getElementById("original");
// const zdepth = document.getElementById("zdepth");
gl.dataset.imageOriginal = original.src;
gl.dataset.imageDepth = original.src;
const anim = new Sketch();
const mediaQuery = window.matchMedia("(prefers-reduced-motion: reduce)");
mediaQuery.addEventListener("change", () => {
  if (mediaQuery.matches) {
    anim.disable();
  } else {
    anim.reenable();
  }
});
